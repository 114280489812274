import {
  Alert,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import {
  LoyaltyDetailFragment,
  useGetLoyaltyInfoLazyQuery,
  useSettleLoyaltyAccountMutation,
} from "../../graphql";
import { isEmpty } from "lodash";
import { ArrowBackIos, CoffeeOutlined, People } from "@mui/icons-material";
import SavingsIcon from "@mui/icons-material/Savings";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import moment from "moment";

export enum METHOD {
  COFFEE,
  MONEY,
  POINTS,
}

export enum SUB_METHODS {
  COFFEE_CONSUME,
  COFFEE_10_BUY,
  COFFEE_20_BUY,
  MONEY_UP,
  MONEY_DOWN,
}

export const LoyaltyPage = () => {
  const [cardNumber, setCardNumber] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [customer, setCustomer] = useState<LoyaltyDetailFragment | null>(null);
  const [method, setMethod] = useState<METHOD | null>(null);
  const [subMethod, setSubMethod] = useState<SUB_METHODS | null>(null);
  const [customMoney, setCustomMoney] = useState<number>(0);

  const [getLoyalty, { loading, refetch, data }] = useGetLoyaltyInfoLazyQuery({
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onCompleted: (res) => {
      if (res.getLoyaltyInfo.isSuccess && res.getLoyaltyInfo.loyaltyDetail) {
        setError("");
      } else {
        setError(res.getLoyaltyInfo.message);
        setCustomer(null);
      }
    },
    onError: (error) => {
      console.log("error", error);
      setError("Ismeretlen hiba");
    },
  });

  useEffect(() => {
    if (
      data &&
      data.getLoyaltyInfo &&
      data.getLoyaltyInfo.isSuccess &&
      data.getLoyaltyInfo.loyaltyDetail
    ) {
      setCustomer(data.getLoyaltyInfo.loyaltyDetail);
    }
  }, [data]);

  const [settle, { loading: settlementLoading }] =
    useSettleLoyaltyAccountMutation({
      onCompleted: (res) => {
        if (res.loyaltyAccountChange.isSuccess) {
          //setCustomer(null);
          setSubMethod(null);
          setMethod(null);
          setCustomMoney(0);
          refetch();
        } else {
          setError(res.loyaltyAccountChange.message);
        }
      },
      onError: (err) => {
        console.log("error", err);
        setError("Nem sikerült a műveletet végrehajtani");
      },
    });

  useEffect(() => {
    setSubMethod(null);
  }, [method]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Grid container spacing={1}>
          {!customer && (
            <>
              <Grid item xs={12}>
                <TextField
                  autoFocus={true}
                  value={cardNumber}
                  autoComplete="none"
                  label="Pöti kártya szám"
                  onChange={(e) => setCardNumber(e.target.value.toUpperCase())}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  disabled={!cardNumber.length}
                  onClick={() => {
                    getLoyalty({
                      variables: {
                        code: cardNumber,
                      },
                    });
                  }}
                  style={{ height: "60px" }}
                  size="large"
                  variant="contained"
                  fullWidth
                >
                  Keresés
                </Button>
              </Grid>
            </>
          )}
          {!isEmpty(error) && (
            <Grid item xs={12}>
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}
          {loading && (
            <Grid textAlign={"center"} item xs={12}>
              <CircularProgress size={150} />
            </Grid>
          )}
          {customer && (
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <IconButton
                  onClick={() => {
                    location.reload();
                  }}
                >
                  <Typography
                    alignItems={"center"}
                    display={"flex"}
                    variant="h6"
                  >
                    <ArrowBackIos />
                    Új keresés indítása
                  </Typography>
                </IconButton>
                <Divider />
                <Divider />
              </Grid>
              <Grid item xs={6}>
                <Typography alignItems={"center"} display={"flex"} variant="h6">
                  <People style={{ marginRight: 10 }} />
                  {customer.name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography alignItems={"center"} display={"flex"} variant="h6">
                  <QrCode2Icon /> {customer.code}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h5" alignItems={"center"} display={"flex"}>
                  <CoffeeOutlined style={{ fontSize: 40 }} />
                  {customer.coffee} db
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  variant={"h5"}
                  alignItems={"center"}
                  display={"flex"}
                >
                  <SavingsIcon style={{ fontSize: 40 }} />
                  {customer.money} Ft
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h5" alignItems={"center"} display={"flex"}>
                  <LoyaltyIcon style={{ fontSize: 40 }} />
                  {customer.points} db
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Button
                  disabled={settlementLoading}
                  style={{ height: "60px" }}
                  size="large"
                  variant="contained"
                  color={method === METHOD.COFFEE ? "warning" : "primary"}
                  fullWidth
                  onClick={() => {
                    setMethod(METHOD.COFFEE);
                  }}
                >
                  Kávébérlet
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  size="large"
                  style={{ height: "60px" }}
                  variant="contained"
                  color={method === METHOD.MONEY ? "warning" : "primary"}
                  fullWidth
                  disabled={settlementLoading}
                  onClick={() => {
                    setMethod(METHOD.MONEY);
                  }}
                >
                  Egyenleg
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  size="large"
                  style={{ height: "60px" }}
                  variant="contained"
                  fullWidth
                  color={method === METHOD.POINTS ? "warning" : "primary"}
                  disabled={settlementLoading}
                  onClick={() => {
                    setMethod(METHOD.POINTS);
                  }}
                >
                  Pöti pontok
                </Button>
              </Grid>
              {method === METHOD.COFFEE && (
                <>
                  <Grid item xs={4}>
                    <Button
                      style={{ height: "60px" }}
                      color={
                        subMethod === SUB_METHODS.COFFEE_CONSUME
                          ? "warning"
                          : "secondary"
                      }
                      variant="contained"
                      disabled={settlementLoading || customer.coffee <= 0}
                      fullWidth
                      onClick={() => {
                        setSubMethod(SUB_METHODS.COFFEE_CONSUME);
                      }}
                    >
                      Kávé fogyasztás
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      style={{ height: "60px" }}
                      variant="contained"
                      disabled={settlementLoading}
                      color={
                        subMethod === SUB_METHODS.COFFEE_10_BUY
                          ? "warning"
                          : "secondary"
                      }
                      fullWidth
                      onClick={() => {
                        setSubMethod(SUB_METHODS.COFFEE_10_BUY);
                      }}
                    >
                      10db KBérlet
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      size="large"
                      style={{ height: "60px" }}
                      variant="contained"
                      disabled={settlementLoading}
                      color={
                        subMethod === SUB_METHODS.COFFEE_20_BUY
                          ? "warning"
                          : "secondary"
                      }
                      fullWidth
                      onClick={() => {
                        setSubMethod(SUB_METHODS.COFFEE_20_BUY);
                      }}
                    >
                      20db KBérlet
                    </Button>
                  </Grid>
                </>
              )}
              {method === METHOD.MONEY && (
                <>
                  <Grid item xs={4}>
                    <Button
                      style={{ height: "60px" }}
                      variant="contained"
                      fullWidth
                      disabled={settlementLoading || customer.money <= 0}
                      color={
                        subMethod === SUB_METHODS.MONEY_DOWN
                          ? "warning"
                          : "secondary"
                      }
                      onClick={() => {
                        setSubMethod(SUB_METHODS.MONEY_DOWN);
                      }}
                    >
                      Pénz felhasználás
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      style={{ height: "60px" }}
                      variant="contained"
                      disabled={settlementLoading}
                      color={
                        subMethod === SUB_METHODS.MONEY_UP
                          ? "warning"
                          : "secondary"
                      }
                      fullWidth
                      onClick={() => {
                        setSubMethod(SUB_METHODS.MONEY_UP);
                      }}
                    >
                      Pénz feltöltés
                    </Button>
                  </Grid>
                  <Grid item xs={4} />
                </>
              )}
              {method === METHOD.POINTS && (
                <Grid item xs={4}>
                  <Typography>Hamarosan...</Typography>
                </Grid>
              )}
              {subMethod === SUB_METHODS.COFFEE_CONSUME && (
                <Grid item xs={12}>
                  <Button
                    disabled={settlementLoading}
                    style={{ height: "60px" }}
                    variant="contained"
                    color="error"
                    fullWidth
                    onClick={() => {
                      settle({
                        variables: {
                          input: {
                            code: customer.code,
                            reason: "1db Kávéfogyasztás",
                            coffee: -1,
                            money: 0,
                            points: 0,
                          },
                        },
                      });
                    }}
                  >
                    Kávé fogyasztás rögzítése
                  </Button>
                </Grid>
              )}
              {subMethod === SUB_METHODS.COFFEE_10_BUY && (
                <Grid item xs={12}>
                  <Button
                    disabled={settlementLoading}
                    style={{ height: "60px" }}
                    variant="contained"
                    color="error"
                    fullWidth
                    onClick={() => {
                      settle({
                        variables: {
                          input: {
                            code: customer.code,
                            reason: "10 alkalmas kávé bérlet vásárlás",
                            coffee: 10,
                            money: 0,
                            points: 0,
                          },
                        },
                      });
                    }}
                  >
                    10 darabos kávébérlet rögzítése
                  </Button>
                </Grid>
              )}
              {subMethod === SUB_METHODS.COFFEE_20_BUY && (
                <Grid item xs={12}>
                  <Button
                    disabled={settlementLoading}
                    style={{ height: "60px" }}
                    variant="contained"
                    color="error"
                    fullWidth
                    onClick={() => {
                      settle({
                        variables: {
                          input: {
                            code: customer.code,
                            reason: "10 alkalmas kávé bérlet vásárlás",
                            coffee: 20,
                            money: 0,
                            points: 0,
                          },
                        },
                      });
                    }}
                  >
                    20 darabos kávébérlet rögzítése
                  </Button>
                </Grid>
              )}
              {subMethod === SUB_METHODS.MONEY_UP && (
                <>
                  <Grid item xs={4}>
                    <Button
                      disabled={settlementLoading}
                      style={{ height: "60px" }}
                      variant="contained"
                      color="error"
                      fullWidth
                      onClick={() => {
                        settle({
                          variables: {
                            input: {
                              code: customer.code,
                              reason: "2 000 Ft egyenleg feltöltés",
                              coffee: 0,
                              money: 2000,
                              points: 0,
                            },
                          },
                        });
                      }}
                    >
                      2 000 Ft
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      style={{ height: "60px" }}
                      variant="contained"
                      color="error"
                      disabled={settlementLoading}
                      fullWidth
                      onClick={() => {
                        settle({
                          variables: {
                            input: {
                              code: customer.code,
                              reason: "5 000 Ft egyenleg feltöltés",
                              coffee: 0,
                              money: 5000,
                              points: 0,
                            },
                          },
                        });
                      }}
                    >
                      5 000 Ft
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      style={{ height: "60px" }}
                      variant="contained"
                      color="error"
                      disabled={settlementLoading}
                      fullWidth
                      onClick={() => {
                        settle({
                          variables: {
                            input: {
                              code: customer.code,
                              reason: "10 000 Ft egyenleg feltöltés",
                              coffee: 0,
                              money: 10000,
                              points: 0,
                            },
                          },
                        });
                      }}
                    >
                      10 000 Ft
                    </Button>
                  </Grid>
                </>
              )}
              {subMethod === SUB_METHODS.MONEY_DOWN && (
                <>
                  <Grid item xs={4}>
                    <Button
                      disabled={settlementLoading}
                      style={{ height: "60px" }}
                      variant="contained"
                      color="error"
                      fullWidth
                      onClick={() => {
                        settle({
                          variables: {
                            input: {
                              code: customer.code,
                              reason: `${customer.money} Ft felhasználása`,
                              coffee: 0,
                              money: -customer.money,
                              points: 0,
                            },
                          },
                        });
                      }}
                    >
                      {customer.money} Ft
                    </Button>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      disabled={settlementLoading}
                      variant="outlined"
                      value={customMoney}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        const newValue =
                          value > customer.money ? customer.money : value;
                        setCustomMoney(Number(newValue));
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      disabled={settlementLoading || customMoney <= 0}
                      style={{ height: "60px" }}
                      variant="contained"
                      color="error"
                      fullWidth
                      onClick={() => {
                        settle({
                          variables: {
                            input: {
                              code: customer.code,
                              reason: `${customMoney.toString()} Ft felhasználása`,
                              coffee: 0,
                              money: -customMoney,
                              points: 0,
                            },
                          },
                        });
                      }}
                    >
                      Egyedi
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item xs={4}>
        <Grid mb={2} item xs={12}>
          <Typography variant="h6">Előzmények</Typography>
          <Divider />
        </Grid>
        {customer &&
          customer.transactions.map((t) => (
            <Grid item xs={12}>
              <Typography variant="body1" fontSize={12}>
                {moment(t.createdAt).format("YYYY-MM-DD HH:mm")}
              </Typography>
              <Typography variant="body1">
                {t.reason} - By {t.createdBy}
              </Typography>
              <Divider />
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
};
