import { action, Action } from "easy-peasy";

export interface UserData {
  name: string;
  token: string | null;
}

export interface UserStore {
  data: UserData;
  update: Action<UserStore, Partial<UserData>>;
  reset: Action<UserStore>;
}

const initialData: UserData = {
  name: "",
  token: null,
};

export const userStore: UserStore = {
  data: initialData,
  update: action((state, payload) => {
    state.data = { ...state.data, ...payload };
  }),
  reset: action((state) => {
    state.data = initialData;
  }),
};
