import { Button, Grid, styled } from "@mui/material";
import React, { PropsWithChildren, useEffect, useState } from "react";

const NumPadButton = styled(Button)(() => ({
  height: 70,
  fontSize: 26,
}));

export const NumPad: React.FC<
  PropsWithChildren<{
    onValueChange: (value: number) => void;
    onEnter: () => void;
    disabled?: boolean;
    enterDisabled?: boolean;
  }>
> = ({ onValueChange, onEnter, disabled = false, enterDisabled = false }) => {
  const [value, setValue] = useState<string>("");
  const calculate = (e: string) => {
    if (e === "Enter") {
      onEnter();
      return;
    }

    if (e === "<-") {
      if (value.length > 0) {
        setValue(value.slice(0, value.length - 1));
        onValueChange(Number(value));
      }
    } else {
      setValue(value + e);
    }
  };

  useEffect(() => {
    onValueChange(Number(value));
  }, [value]);

  return (
    <Grid spacing={3} container>
      {padNumbers.map((n, i) => (
        <Grid key={i} item xs={4}>
          <NumPadButton
            disabled={disabled || (n === "Enter" ? enterDisabled : false)}
            onClick={() => {
              calculate(n);
            }}
            fullWidth
            size="large"
            variant="contained"
          >
            {n}
          </NumPadButton>
        </Grid>
      ))}
    </Grid>
  );
};

const padNumbers = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "0",
  "<-",
  "Enter",
];
