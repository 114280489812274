import { Alert, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { NumPad } from "../../components/Numpad";
import { useLoginMutation } from "../../graphql";
import { useStoreActions } from "../../stores";
import { useNavigate } from "react-router-dom";

export const LoginPage: React.FC = () => {
  const [error, setError] = useState<null | string>(null);
  const [userName, setUserName] = useState<string | null>(null);
  const [password, setPassword] = useState<string>("");
  const navigate = useNavigate();

  const updateUser = useStoreActions((action) => action.user.update);

  const users = ["Juci", "Márti", "Dorci", "Robi", "Andris", "Bálint"];
  const hidedPassword = password.length
    ? "*".repeat(password.length)
    : "_ _ _ _";

  const [login, { loading }] = useLoginMutation({
    variables: {
      input: {
        email: userName || "",
        password: password || "",
      },
    },
    onCompleted: (res) => {
      if (res && res.login && res.login.isSuccess) {
        setError(null);
        updateUser({ token: res.login.message, name: userName || "" });
        navigate("home");
      } else if (res && res.login.message) {
        setError(res.login.message);
      }
    },
    onError: (err) => {
      console.error("[Login][Error]", err);
      setError("Ismeretlen hiba");
    },
  });

  return (
    <Grid container justifyContent={"center"} alignItems={"center"}>
      <Grid item xs={12}>
        <Grid spacing={3} container justifyContent={"center"}>
          <Grid item xs={12}>
            <Grid spacing={3} justifyContent={"center"} container>
              {users.map((u, i) => (
                <Grid key={i} item md={2} xs={3}>
                  <Button
                    color={u === userName ? "success" : "secondary"}
                    onClick={() => {
                      setUserName(u);
                    }}
                    style={{ height: "70px" }}
                    size="large"
                    fullWidth
                    variant="contained"
                  >
                    {u}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            {error && (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>{" "}
              </Grid>
            )}
            <Typography textAlign={"center"}>
              {!userName ? "Válassz profilt" : hidedPassword}
            </Typography>
          </Grid>
          {userName && (
            <Grid item xs={12} md={6}>
              <NumPad
                enterDisabled={password.length < 4}
                disabled={loading}
                onEnter={() => {
                  login();
                }}
                onValueChange={(s: number) => {
                  setPassword(Number(s) === 0 ? "" : s.toString());
                }}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
