import React from "react";
import { Route, Routes } from "react-router-dom";
import { LoginPage } from "../pages/login/login.page";
import { useStoreState } from "../stores";
import { SalePage } from "../pages/sale/sale.page";
import { LoyaltyPage } from "../pages/loyalty/loyalty.page";
import { LoyaltyLogPage } from "../pages/loyaltylog/loyaltylog.page";

export const AppRouter: React.FC = () => {
  const token = useStoreState((state) => state.user.data.token);

  if (!token) {
    return (
      <Routes>
        <Route path="*" element={<LoginPage />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/home" element={<SalePage />} />
      <Route path="/loyalty/log" element={<LoyaltyLogPage />} />
      <Route path="/loyalty" element={<LoyaltyPage />} />
    </Routes>
  );
};
