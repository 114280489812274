import { Grid } from "@mui/material";
import React from "react";

export const SalePage: React.FC = () => {
  return (
    <Grid container>
      <Grid item>Hello Bello</Grid>
    </Grid>
  );
};
