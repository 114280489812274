import React from "react";
import "./App.css";
import { AppRehydratationProvider } from "./providers/AppRehydratationProvider";
import { MainSkeleton } from "./skeleton/Main.skeleton";
import { StoreProvider } from "easy-peasy";
import store from "./stores";
import { AppRouter } from "./router/AppRouter";
import { BrowserRouter as Router } from "react-router-dom";
import { ApolloContextProvider } from "./providers/ApolloContextProvider";

function App() {
  return (
    <Router>
      <StoreProvider store={store}>
        <AppRehydratationProvider>
          <ApolloContextProvider>
            <MainSkeleton>
              <AppRouter />
            </MainSkeleton>
          </ApolloContextProvider>
        </AppRehydratationProvider>
      </StoreProvider>
    </Router>
  );
}

export default App;
