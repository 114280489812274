import React, { PropsWithChildren } from "react";

import { useStoreRehydrated } from "easy-peasy";

export const AppRehydratationProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const isRehydrated = useStoreRehydrated();
  if (isRehydrated) {
    return <>{children}</>;
  } else {
    return <></>;
  }
};
