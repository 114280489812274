import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export type BaseResult = {
  __typename?: 'BaseResult';
  isSuccess: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
};

export type ContactInput = {
  email: Scalars['String']['input'];
  message: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type LoginInput = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LoyaltyBackstageChangeInput = {
  code: Scalars['String']['input'];
  coffee: Scalars['Int']['input'];
  money: Scalars['Int']['input'];
  points: Scalars['Int']['input'];
  reason: Scalars['String']['input'];
};

export type LoyaltyBackstageResponseDetails = {
  __typename?: 'LoyaltyBackstageResponseDetails';
  code: Scalars['String']['output'];
  coffee: Scalars['Int']['output'];
  money: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  points: Scalars['Int']['output'];
  transactions: Array<LoyaltyTransactionDetails>;
};

export type LoyaltyCardBackstageLogResponse = {
  __typename?: 'LoyaltyCardBackstageLogResponse';
  isSuccess: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
  transactions: Array<LoyaltyTransactionDetails>;
};

export type LoyaltyCardBackstageResponse = {
  __typename?: 'LoyaltyCardBackstageResponse';
  isSuccess: Scalars['Boolean']['output'];
  loyaltyDetail?: Maybe<LoyaltyBackstageResponseDetails>;
  message: Scalars['String']['output'];
};

export type LoyaltyDetails = {
  __typename?: 'LoyaltyDetails';
  code: Scalars['String']['output'];
  coffee: Scalars['Int']['output'];
  money: Scalars['Int']['output'];
  points: Scalars['Int']['output'];
  transactions: Array<LoyaltyTransactionDetails>;
};

export type LoyaltyTransactionDetails = {
  __typename?: 'LoyaltyTransactionDetails';
  coffee: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  createdBy: Scalars['String']['output'];
  id: Scalars['String']['output'];
  money: Scalars['Int']['output'];
  points: Scalars['Int']['output'];
  reason: Scalars['String']['output'];
};

export type MeQueryResponse = {
  __typename?: 'MeQueryResponse';
  email: Scalars['String']['output'];
  loyalty: LoyaltyDetails;
  name: Scalars['String']['output'];
  userId: Scalars['Int']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  login: BaseResult;
  loyaltyAccountChange: BaseResult;
  registration: BaseResult;
  sendMessage: BaseResult;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationLoyaltyAccountChangeArgs = {
  input: LoyaltyBackstageChangeInput;
};


export type MutationRegistrationArgs = {
  input: RegistrationInput;
};


export type MutationSendMessageArgs = {
  input: ContactInput;
};

export type Query = {
  __typename?: 'Query';
  getLoyaltyInfo: LoyaltyCardBackstageResponse;
  getLoyaltyLogs: LoyaltyCardBackstageLogResponse;
  me: MeQueryResponse;
};


export type QueryGetLoyaltyInfoArgs = {
  code: Scalars['String']['input'];
};


export type QueryGetLoyaltyLogsArgs = {
  dateFrom: Scalars['String']['input'];
  dateTo: Scalars['String']['input'];
};

export type RegistrationInput = {
  email: Scalars['String']['input'];
  name: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type LoyaltyDetailFragment = { __typename?: 'LoyaltyBackstageResponseDetails', name: string, points: number, money: number, coffee: number, code: string, transactions: Array<{ __typename?: 'LoyaltyTransactionDetails', coffee: number, money: number, points: number, reason: string, createdAt: any, createdBy: string }> };

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'BaseResult', isSuccess: boolean, message: string } };

export type LoyaltyAccountChangeMutationVariables = Exact<{
  input: LoyaltyBackstageChangeInput;
}>;


export type LoyaltyAccountChangeMutation = { __typename?: 'Mutation', loyaltyAccountChange: { __typename?: 'BaseResult', isSuccess: boolean, message: string } };

export type SettleLoyaltyAccountMutationVariables = Exact<{
  input: LoyaltyBackstageChangeInput;
}>;


export type SettleLoyaltyAccountMutation = { __typename?: 'Mutation', loyaltyAccountChange: { __typename?: 'BaseResult', isSuccess: boolean, message: string } };

export type GetLoyaltyInfoQueryVariables = Exact<{
  code: Scalars['String']['input'];
}>;


export type GetLoyaltyInfoQuery = { __typename?: 'Query', getLoyaltyInfo: { __typename?: 'LoyaltyCardBackstageResponse', isSuccess: boolean, message: string, loyaltyDetail?: { __typename?: 'LoyaltyBackstageResponseDetails', name: string, points: number, money: number, coffee: number, code: string, transactions: Array<{ __typename?: 'LoyaltyTransactionDetails', coffee: number, money: number, points: number, reason: string, createdAt: any, createdBy: string }> } | null } };

export type GetLoyaltyLogsQueryVariables = Exact<{
  dateFrom: Scalars['String']['input'];
  dateTo: Scalars['String']['input'];
}>;


export type GetLoyaltyLogsQuery = { __typename?: 'Query', getLoyaltyLogs: { __typename?: 'LoyaltyCardBackstageLogResponse', message: string, transactions: Array<{ __typename?: 'LoyaltyTransactionDetails', id: string, coffee: number, reason: string, points: number, money: number, createdAt: any, createdBy: string }> } };

export const LoyaltyDetailFragmentDoc = gql`
    fragment LoyaltyDetail on LoyaltyBackstageResponseDetails {
  name
  points
  money
  coffee
  code
  transactions {
    coffee
    money
    points
    reason
    createdAt
    createdBy
  }
}
    `;
export const LoginDocument = gql`
    mutation Login($input: LoginInput!) {
  login(input: $input) {
    isSuccess
    message
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LoyaltyAccountChangeDocument = gql`
    mutation LoyaltyAccountChange($input: LoyaltyBackstageChangeInput!) {
  loyaltyAccountChange(input: $input) {
    isSuccess
    message
  }
}
    `;
export type LoyaltyAccountChangeMutationFn = Apollo.MutationFunction<LoyaltyAccountChangeMutation, LoyaltyAccountChangeMutationVariables>;

/**
 * __useLoyaltyAccountChangeMutation__
 *
 * To run a mutation, you first call `useLoyaltyAccountChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoyaltyAccountChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loyaltyAccountChangeMutation, { data, loading, error }] = useLoyaltyAccountChangeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoyaltyAccountChangeMutation(baseOptions?: Apollo.MutationHookOptions<LoyaltyAccountChangeMutation, LoyaltyAccountChangeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoyaltyAccountChangeMutation, LoyaltyAccountChangeMutationVariables>(LoyaltyAccountChangeDocument, options);
      }
export type LoyaltyAccountChangeMutationHookResult = ReturnType<typeof useLoyaltyAccountChangeMutation>;
export type LoyaltyAccountChangeMutationResult = Apollo.MutationResult<LoyaltyAccountChangeMutation>;
export type LoyaltyAccountChangeMutationOptions = Apollo.BaseMutationOptions<LoyaltyAccountChangeMutation, LoyaltyAccountChangeMutationVariables>;
export const SettleLoyaltyAccountDocument = gql`
    mutation settleLoyaltyAccount($input: LoyaltyBackstageChangeInput!) {
  loyaltyAccountChange(input: $input) {
    isSuccess
    message
  }
}
    `;
export type SettleLoyaltyAccountMutationFn = Apollo.MutationFunction<SettleLoyaltyAccountMutation, SettleLoyaltyAccountMutationVariables>;

/**
 * __useSettleLoyaltyAccountMutation__
 *
 * To run a mutation, you first call `useSettleLoyaltyAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSettleLoyaltyAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [settleLoyaltyAccountMutation, { data, loading, error }] = useSettleLoyaltyAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSettleLoyaltyAccountMutation(baseOptions?: Apollo.MutationHookOptions<SettleLoyaltyAccountMutation, SettleLoyaltyAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SettleLoyaltyAccountMutation, SettleLoyaltyAccountMutationVariables>(SettleLoyaltyAccountDocument, options);
      }
export type SettleLoyaltyAccountMutationHookResult = ReturnType<typeof useSettleLoyaltyAccountMutation>;
export type SettleLoyaltyAccountMutationResult = Apollo.MutationResult<SettleLoyaltyAccountMutation>;
export type SettleLoyaltyAccountMutationOptions = Apollo.BaseMutationOptions<SettleLoyaltyAccountMutation, SettleLoyaltyAccountMutationVariables>;
export const GetLoyaltyInfoDocument = gql`
    query GetLoyaltyInfo($code: String!) {
  getLoyaltyInfo(code: $code) {
    isSuccess
    message
    loyaltyDetail {
      ...LoyaltyDetail
    }
  }
}
    ${LoyaltyDetailFragmentDoc}`;

/**
 * __useGetLoyaltyInfoQuery__
 *
 * To run a query within a React component, call `useGetLoyaltyInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoyaltyInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoyaltyInfoQuery({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useGetLoyaltyInfoQuery(baseOptions: Apollo.QueryHookOptions<GetLoyaltyInfoQuery, GetLoyaltyInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoyaltyInfoQuery, GetLoyaltyInfoQueryVariables>(GetLoyaltyInfoDocument, options);
      }
export function useGetLoyaltyInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoyaltyInfoQuery, GetLoyaltyInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoyaltyInfoQuery, GetLoyaltyInfoQueryVariables>(GetLoyaltyInfoDocument, options);
        }
export type GetLoyaltyInfoQueryHookResult = ReturnType<typeof useGetLoyaltyInfoQuery>;
export type GetLoyaltyInfoLazyQueryHookResult = ReturnType<typeof useGetLoyaltyInfoLazyQuery>;
export type GetLoyaltyInfoQueryResult = Apollo.QueryResult<GetLoyaltyInfoQuery, GetLoyaltyInfoQueryVariables>;
export const GetLoyaltyLogsDocument = gql`
    query GetLoyaltyLogs($dateFrom: String!, $dateTo: String!) {
  getLoyaltyLogs(dateFrom: $dateFrom, dateTo: $dateTo) {
    message
    transactions {
      id
      coffee
      reason
      points
      money
      createdAt
      createdBy
    }
  }
}
    `;

/**
 * __useGetLoyaltyLogsQuery__
 *
 * To run a query within a React component, call `useGetLoyaltyLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLoyaltyLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLoyaltyLogsQuery({
 *   variables: {
 *      dateFrom: // value for 'dateFrom'
 *      dateTo: // value for 'dateTo'
 *   },
 * });
 */
export function useGetLoyaltyLogsQuery(baseOptions: Apollo.QueryHookOptions<GetLoyaltyLogsQuery, GetLoyaltyLogsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLoyaltyLogsQuery, GetLoyaltyLogsQueryVariables>(GetLoyaltyLogsDocument, options);
      }
export function useGetLoyaltyLogsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLoyaltyLogsQuery, GetLoyaltyLogsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLoyaltyLogsQuery, GetLoyaltyLogsQueryVariables>(GetLoyaltyLogsDocument, options);
        }
export type GetLoyaltyLogsQueryHookResult = ReturnType<typeof useGetLoyaltyLogsQuery>;
export type GetLoyaltyLogsLazyQueryHookResult = ReturnType<typeof useGetLoyaltyLogsLazyQuery>;
export type GetLoyaltyLogsQueryResult = Apollo.QueryResult<GetLoyaltyLogsQuery, GetLoyaltyLogsQueryVariables>;