import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";

export const useApollo = (token: string) => {
  const httplink = new HttpLink({
    uri: "https://api.potikave.hu/graphql",
    //uri: "http://localhost:4005/graphql",
    headers: {
      authorization: token,
    },
  });

  const getApolloConfig = () => {
    return new ApolloClient({
      cache: new InMemoryCache(),
      link: httplink,
    });
  };

  return [getApolloConfig];
};
