import { Grid } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useGetLoyaltyLogsQuery } from "../../graphql";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState } from "react";
import dayjs, { Dayjs } from "dayjs";

export const LoyaltyLogPage = () => {
  const fields: GridColDef[] = [
    { field: "id", headerName: "ID", flex: 1 },
    {
      field: "createdAt",
      headerName: "Létrehozva",
      flex: 1,
      valueGetter: (data) => {
        return moment(data.row.createdAt).format("YYYY-MM-DD HH:mm");
      },
    },
    { field: "reason", headerName: "Üzenet", flex: 1 },
    { field: "createdBy", headerName: "Végrehajtó", flex: 1 },
  ];
  const [startDate, setStartDate] = useState<Dayjs | null>(
    dayjs().startOf("day")
  );
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs().endOf("day"));

  const { data, loading } = useGetLoyaltyLogsQuery({
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    variables: {
      dateFrom: startDate?.format("YYYY-MM-DD HH:mm") || "",
      dateTo: endDate?.format("YYYY-MM-DD HH:mm") || "",
    },
  });

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Grid container spacing={2}>
          <Grid item>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={startDate}
                onChange={(e) => {
                  setStartDate(e);
                }}
                label="Start Date"
              />
            </LocalizationProvider>
          </Grid>
          <Grid item>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                value={endDate}
                onChange={(e) => {
                  setEndDate(e);
                }}
                label="End Date"
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <DataGrid
          loading={loading}
          rows={data?.getLoyaltyLogs.transactions || []}
          columns={fields}
          autoHeight
        />
      </Grid>
    </Grid>
  );
};
